<template>
  <div
    id="updated-at"
    class="support-rtl"
  >
    <span>
      {{ $t('convert.updated_at_with_colon') }}
    </span>
    <span>
      {{ updatedAt }}
    </span>
    <i
      ref="infoIcon"
      class="fa-solid fa-circle-info has-text-info"
      @click="toggleModal"
    />
    <Transition>
      <div
        v-if="showModal"
        class="modal"
      >
        <div
          class="modal-background"
          @click="toggleModal"
        />
        <div class="modal-content">
          <article class="message is-primary">
            <div class="message-header is-primary support-rtl">
              <p class="has-text-white">{{ $t('data_source') }}</p>
              <button
                class="delete"
                aria-label="delete"
                @click="toggleModal"
              />
            </div>
            <div class="message-body support-rtl">
              <i18n-t keypath="data_source_explanation">
                <a
                  href="https://fxratesapi.com/"
                  target="_blank"
                >https://fxratesapi.com/</a>
              </i18n-t>
            </div>
          </article>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  timestamp: {
    type: Number,
    default: () => Math.floor(Date.now())
  }
});

const i18nLocale = useI18n();
const updatedAt = computed(() => {
  const date = new Date(props.timestamp);
  return date.toLocaleString(i18nLocale.locale.value);
});

const showModal = ref(false);
const toggleModal = () => {
  showModal.value = !showModal.value;
}
</script>

<style lang="scss" scoped>
#updated-at {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
}

i {
  cursor: pointer;
}

.modal {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>